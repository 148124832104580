<template>
  <div
    class="
      min-h-screen
      flex
      items-center
      justify-center
      bg-gray-50
      py-12
      px-4
      sm:px-6
      lg:px-8
    "
  >
    <div
      :class="isLoading ? 'hidden' : 'block'"
      class="max-w-md w-full space-y-8"
    >
      <div>
        <img
          class="mx-auto h-12 w-auto"
          src="../../assets/mystidia.png"
          alt="Mystidia"
        />
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Changez votre mot de passe
        </h2>
      </div>
      <form class="mt-8 space-y-6" @submit.prevent="change">
        <input type="hidden" name="remember" value="true" />
        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <label for="password" class="sr-only">Mot de passe</label>
            <input
              v-model="form.password"
              id="password"
              name="password"
              type="password"
              autocomplete="email"
              required="required"
              class="
                appearance-none
                rounded-none
                relative
                block
                w-full
                px-3
                py-3
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-t-md
                focus:outline-none
                focus:ring-red-400
                focus:border-red-400
                focus:z-10
                sm:text-sm
              "
              placeholder="Mot de passe"
            />
          </div>
          <div>
            <label for="password_confirmation" class="sr-only"
              >Confirmation de mot de passe</label
            >
            <input
              v-model="form.password_confirmation"
              id="password_confirmation"
              name="password_confirmation"
              type="password"
              autocomplete="current-password"
              required="required"
              class="
                appearance-none
                rounded-none
                relative
                block
                w-full
                px-3
                py-3
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-b-md
                focus:outline-none
                focus:ring-red-400
                focus:border-red-400
                focus:z-10
                sm:text-sm
              "
              placeholder="confirmation de mot de passe"
            />
          </div>
        </div>

        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <label
              for="remember-me"
              class="ml-2 block text-sm text-gray-900"
            ></label>
          </div>
        </div>

        <div>
          <button
            type="submit"
            class="
              group
              relative
              w-full
              flex
              justify-center
              py-3
              px-4
              border border-transparent
              text-sm
              font-medium
              rounded-md
              text-white
              bg-red-400
              hover:bg-red-500
              focus:outline-none
              focus:ring-2 focus:ring-offset-2 focus:ring-red-400
            "
          >
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <!-- Heroicon name: solid/lock-closed -->
              <svg
                class="h-5 w-5 text-red-600 group-hover:text-red-300"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
            Changer le mot de passe
          </button>
        </div>
      </form>
    </div>

    <!--<div
      :class="isLoading ? 'block' : 'hidden'"
      class="fulfilling-square-spinner"
    >
      <div class="spinner-inner"></div>
    </div>-->
    <div :class="isLoading ? 'block' : 'hidden'">
      <div class="loading">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      form: {
        password: "",
        password_confirmation: "",
        resetToken: "",
        email: "",
      },
      error: null,
      isLoading: false,
    };
  },
  mounted() {
    this.form.resetToken = this.$route.query.token;
    this.form.email = this.$route.query.email;
  },
  methods: {
    change() {
      axios
        .post(process.env.VUE_APP_API_BASE_URL+"/resetPassword", this.form)
        .then(({ data }) => {
          this.$router.push("/");
        })
        .catch((error) => {
          this.error = error.response.data.errors;
        });
    },
  },
};
</script>

<style>
.fulfilling-square-spinner,
.fulfilling-square-spinner * {
  box-sizing: border-box;
}

.fulfilling-square-spinner {
  height: 100px;
  width: 100px;
  position: relative;
  border: 4px solid #f87171;
  animation: fulfilling-square-spinner-animation 4s infinite ease;
}

.fulfilling-square-spinner .spinner-inner {
  vertical-align: top;
  display: inline-block;
  background-color: #f87171;
  width: 100%;
  opacity: 1;
  animation: fulfilling-square-spinner-inner-animation 4s infinite ease-in;
}

@keyframes fulfilling-square-spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fulfilling-square-spinner-inner-animation {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}
</style>